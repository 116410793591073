import Swal from 'sweetalert2';

const showAlert = (
	text: string,
	title: string | null = null,
	type: 'success' | 'error' | 'warning' = 'error',
	allowOutsideClick = true,
	allowEscapeKey = true,
	backdrop = false,
	redirectURL: string | null = null,
	navigate: ((path: string) => void) | null = null,
	logout: (() => void) | null = null
) =>
	Swal.fire({
		icon: type,
		title: title || undefined,
		text: text,
		allowOutsideClick,
		allowEscapeKey,
		backdrop,
	}).then((result) => {
		if (result.isConfirmed && redirectURL && navigate) {
			navigate(redirectURL);
		}
		if (result.isConfirmed && logout) {
			logout();
		}
	});

export default showAlert;
