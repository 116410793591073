import { Header } from '@/components';
import InsuredCustomerContactForm from '@/components/InsuredCustomerForm/ContactForm';

const InsuredCustomerContactPage = () => {
	return (
		<>
			<Header title="Insured Customer" fullTitle="Insured Customer" />
			<InsuredCustomerContactForm />
		</>
	);
};

export default InsuredCustomerContactPage;
