// useCliniciansData.jsx or .ts
import { useEffect, useState } from 'react';

export const useCliniciansData = () => {
	const config = window.envConfig;
	const [clinicians, setClinicians] = useState([]);

	useEffect(() => {
		const fetchClinicianData = async () => {
			try {
				const response = await fetch(`${config.REACT_APP_API_URL}/booking-users`);
				const data = await response.json();

				if (Array.isArray(data.clinicians)) {
					setClinicians(data.clinicians);
				} else {
					setClinicians([]);
				}
			} catch (error) {
				console.error('Error fetching clinician data:', error);
				setClinicians([]);
			}
		};

		fetchClinicianData();
	}, []);

	return clinicians;
};
