/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-key */
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AllContext';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import Sidebar from '../Shared/Sidebar';
import PageTitle from '../PageTitle';
import SirkkaLogo from '../SirkkaLogo';
import './Header.scss';
import Searchbar from './Searchbar';
import useFetchMembershipData from '../../hooks/useFetchMembershipData';
import Modal from '../Modal';

const Header = ({ title, fullTitle }) => {
	const [promoMessage, setPromoMessage] = useState(''); //Set a promotion message here

	const { promoBannerVisible, setPromoBannerVisible } = useContext(AuthContext);
	const [isDropdownHovered, setIsDropdownHovered] = useState(false);
	const [show, setShow] = useState(false);
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { isAuthenticated, logout } = useAuth();

	const membershipData = useFetchMembershipData();

	const navConfig = [
		{
			name: 'Insured Customer',
			link: '/insured',
		},
		{
			name: 'Wellbeing',
			link: '/wellbeing',
		},
		{
			name: 'Group Testing',
			link: '/events',
		},
		{
			name: 'International Treatments',
			link: '/international-treatments',
		},
		{
			name: 'Imaging',
			link: '/imaging',
		},
		// { name: 'Mental Health', link: '/mental-health' },
		{
			name: 'Specialists',
			link: '/specialists',
		},
		{
			name: 'Diagnostic Services',
			link: '/booking',
			subMenu: [
				{
					category: 'General Health',
					links: [
						{ label: 'Standard Health Screening', serviceCode: 'standardHealthScreening' },
						// { label: 'Kidney & Liver Function', serviceCode: 'kidneyLiverFunction' },
						// { label: 'Liver Function', serviceCode: 'liverFunction' },
						// { label: 'Full Blood Count', serviceCode: 'fullBloodCount' },
						{ label: 'Iron Panel', serviceCode: 'ironPanel' },
						{ label: 'Urine', serviceCode: 'Urine' },
						{ label: 'Understand Your Weight', serviceCode: 'weightManagement' },
						{ label: 'Vitamin D', serviceCode: 'vitaminD' },
						{ label: 'Chronic Fatigue package', serviceCode: 'chronicFatiguePackage' },
						// { label: 'Immunisation Tests', serviceCode: 'immunisationTests' },
						{ label: 'Allergy Testing', serviceCode: 'allergyTest' },
					],
				},
				{
					category: 'Heart Health',
					links: [
						{ label: 'Heart Health', serviceCode: 'heartHealth' },
						{ label: 'PLAC', serviceCode: 'PLAC' },
						// { label: 'LIPIDs', serviceCode: 'lipids' },
						{ label: 'Resting ECG', serviceCode: 'restingECG' },
						{ span: 'Specialist' },
						{ label: 'Dr Oliver Guttmann', specialistCode: '191' },
						{ label: 'Dr Jonathan Clague', specialistCode: '192' },
					],
				},
				{
					category: 'Mental Health',
					links: [
						{ span: 'Counsellor' },
						{ label: 'Gigi Dryer', specialistCode: '194' },
						{ label: 'Agneta Lindberg', specialistCode: '200' },
						{ span: 'Psychologist' },
						{ label: 'Elaine McClement', specialistCode: '195' },
						{ label: 'Dr. Johanna Flack', specialistCode: '196' },
						{ label: 'Ms. Ritu Kalsi', specialistCode: '197' },
					],
				},
				{
					category: 'Gut Health',
					links: [
						{ label: 'Gut Health', serviceCode: 'gutHealth' },
						{ label: 'Calprotectin', serviceCode: 'Calprotectin' },
						{ label: 'QFIT', serviceCode: 'QFIT' },
						{ span: 'Specialist' },
						{ label: 'Dr Natalie Direkze', specialistCode: '201' },
						{ label: 'Mr James Kinross', specialistCode: '184' },
					],
				},
				{
					category: 'Sports Health',
					links: [
						{ label: 'Sport Health Package', serviceCode: 'sportHealthPackage' },
						{ label: 'Heart Health', serviceCode: 'heartHealth' },
					],
				},
				{
					category: 'Endocrinology',
					links: [
						{ label: 'Male Hormone Screening', serviceCode: 'maleHormoneScreening' },
						{ label: 'Female Hormone Screening', serviceCode: 'femaleHormoneScreening' },
						{ label: 'Thyroid Screening', serviceCode: 'thyroidScreening' },
						{ span: 'Specialist' },
						{ label: 'Prof. Maria Kyrgiou', specialistCode: '187' },
						{ label: 'Prof. Christopher Nutting', specialistCode: '189' },
						{ label: 'Ms Melanie Tipples', specialistCode: '190' },
						{ label: 'Dr Mark Vanderpump', specialistCode: '193' },
					],
				},
				{
					category: 'Cancer Screening',
					links: [
						{ label: 'Cancer Markers', serviceCode: 'cancerMarkers' },
						{ label: 'PSA', serviceCode: 'PSA' },
						{ label: 'QFIT', serviceCode: 'QFIT' },
						{ span: 'Specialist' },
						{ label: 'Prof. Christopher Nutting', specialistCode: '189' },
						{ label: 'Mr Iain Nixon', specialistCode: '188' },
						{ label: 'Dr Dimitri Hadjiminas', specialistCode: '185' },
						{ label: 'Dr Crispin Hiley', specialistCode: '203' },
						{ label: 'Mr James Kinross', specialistCode: '184' },
						{ label: 'Dr Natalie Direkze', specialistCode: '201' },
					],
				},
				{
					category: 'Women’s Health',
					links: [
						{ label: 'Female Hormone Screening', serviceCode: 'femaleHormoneScreening' },
						{ label: 'HIV', serviceCode: 'HIV' },
						{ label: 'Syphillis', serviceCode: 'Syphilis' },
						{ label: 'HPV', serviceCode: 'HPV' },
						{ span: 'GP' },
						{ label: 'Dr Simon Smail', specialistCode: '198' },
						{ label: 'Dr Mariam Hameed', specialistCode: '199' },
						{ span: 'Specialist' },
						{ label: 'Dr Maria Kyrgiou', specialistCode: '187' },
						{ label: 'Dr Melanie Tipples', specialistCode: '190' },
					],
				},
				{
					category: 'Men’s Health',
					links: [
						{ label: 'Male Hormone Screening', serviceCode: 'maleHormoneScreening' },
						{ label: 'PSA', serviceCode: 'PSA' },
						{ label: 'HIV', serviceCode: 'HIV' },
						{ label: 'Syphillis', serviceCode: 'Syphilis' },
						{ span: 'GP' },
						{ label: 'Dr Simon Smail', specialistCode: '198' },
						{ label: 'Dr Mariam Hameed', specialistCode: '199' },
					],
				},
				{
					category: 'Pre-Fertility',
					links: [
						{ label: 'Pre-Fertility Treatment Tests', serviceCode: 'preFertilityTreatment' },
						{ span: 'Specialist' },
						{ label: 'Dr Vishakha Tripathi', specialistCode: '186' },
					],
				},
				{
					category: 'Other Tests',
					links: [
						{
							label: 'Travel & Pre-Employment Medicals',
							serviceCode: 'travelPreEmploymentTests',
						},
						{ label: 'COVID-19 PCR', serviceCode: 'chinaboundPCR24hr' },
					],
				},
				{
					category: 'Imaging',
					links: [
						{ label: 'Resting ECG', serviceCode: 'restingECG' },
						{ label: 'Ultrasound Scan', serviceCode: 'ultrasoundScan' },
						{ label: 'Mammogram', serviceCode: 'Mammogram' },
						{ label: 'X-Ray', serviceCode: 'xRay' },
						{ label: 'CT & MRI Scan', serviceCode: 'ctMRI' },
					],
				},
				{
					category: 'Pre/Post Surgery Tests',
					links: [
						{
							label: 'Pre-operative test (Orthopaedic Blood Test)',
							serviceCode: 'preOpTestOrthopaedicBloodTest',
						},
						{
							label: 'Pre-operative test (Bariatric Surgeries)',
							serviceCode: 'preOperativeTestBariatricSurgeries',
						},
						{
							label: 'Full Post-op follow-up blood test (Bariatric Surgery)',
							serviceCode: 'fullPostOpBloodTestBariaticSurgeries',
						},
						{
							label: 'Pre-operative test (Plastic Surgeries)',
							serviceCode: 'preOperativePlasticSurgery',
						},
						{
							label: 'Pre-Operative test Hygeia',
							serviceCode: 'preOpTestTirana',
						},
						{ label: 'Diers Test Profile 1', serviceCode: 'testProfile1' },
						{ label: 'Diers Test Profile 2', serviceCode: 'testProfile2' },
						{ label: 'Diers Test Profile 3', serviceCode: 'testProfile3' },
						{ label: 'Diers Test Profile 4', serviceCode: 'testProfile4' },
					],
				},
			],
		},
	];

	const renderLinks = (links) => {
		return links.map((link, index) => {
			if (link.span) {
				return <span key={index}>{link.span}</span>;
			}

			let urlSuffix = '';
			if (link.serviceCode) {
				urlSuffix = `?serviceCode=${link.serviceCode}`;
			} else if (link.specialistCode) {
				urlSuffix = `?specialistCode=${link.specialistCode}`;
			}

			return (
				<Link key={index} to={`/booking${urlSuffix}`}>
					{link.label || link.name}
				</Link>
			);
		});
	};

	const renderMembershipLink = () => {
		if (!membershipData?.membership_type) {
			return (
				<Link to="/membership" className="search-cta secondary-nav-btn">
					Personal Memberships
				</Link>
			);
		}

		return null;
	};

	const toggleLoginModal = () => {
		setIsLoginModalOpen(!isLoginModalOpen);
	};

	const loginModalContent = (
		<div className="login-modal">
			<h2>Login</h2>
			<p>Are you logging in as an individual or as a business?</p>
			<div className="modal-buttons">
				<Link to="/login" className="secondary-nav-btn">
					Personal Login
				</Link>
				<Link to="/business-login" className="secondary-nav-btn">
					Business Login
				</Link>
			</div>
		</div>
	);

	return (
		<>
			<PageTitle title={title} fullTitle={fullTitle} />

			{/* Promo message */}
			{promoBannerVisible && promoMessage && (
				<div className="header__promo_message">
					<div className="container">
						{promoMessage}
						<i className="fas fa-times close-icon" onClick={() => setPromoBannerVisible(false)}></i>
					</div>
				</div>
			)}

			{/* Top bar */}
			<div className="header__topbar">
				<nav className="navbar container" style={{ padding: 0 }}>
					<div style={{ display: 'flex', gap: '16px' }}>
						{/* <div>
							<i className="fas fa-phone px-2"></i>
							<a href="tel:0330 088 2538">0330 088 2538</a>
						</div> */}
						<div>
							<i className="fas fa-envelope px-2"></i>
							<a href="mailto:customers@sirkkaltd.com">customers@sirkkaltd.com</a>
						</div>
						<div>
							<Link to="https://maps.app.goo.gl/HP4zNxdAXbVzLuMD9" target="_blank">
								Reviews on Google 4.8/5
								<i className="fas fa-star ps-2" style={{ color: '#f9bb02' }}></i>
								<i className="fas fa-star" style={{ color: '#f9bb02' }}></i>
								<i className="fas fa-star" style={{ color: '#f9bb02' }}></i>
								<i className="fas fa-star" style={{ color: '#f9bb02' }}></i>
								<i className="fas fa-star" style={{ color: '#f9bb02' }}></i>
							</Link>
						</div>
					</div>
					<div className="links">
						<Link to="/contact">Contact us</Link>

						{!isAuthenticated && (
							<Link className="topbar-btn" type="button" onClick={toggleLoginModal}>
								Login
							</Link>
						)}

						{isAuthenticated && (
							<>
								<Link to="/customer-dashboard" type="button">
									Dashboard
								</Link>
								<Link className="topbar-btn" type="button" to="#" onClick={logout}>
									Logout
								</Link>
							</>
						)}
					</div>
				</nav>
			</div>

			{/* Search bar menu */}
			<div className="header__searchbar_menu">
				<nav className="navbar container">
					<div className="navbar-logo">
						<Link to="/">
							<SirkkaLogo />
						</Link>
					</div>
					<Searchbar />

					<div className="navbar-additional-links">
						{/* {!membershipData?.membership_type && (
							<Link to="/membership" className="search-cta primary-nav-btn">
							Become a member
						</Link>
						)} */}

						<li>
							<Link to="/gift-cards" className="search-cta primary-nav-btn">
								Gift Cards
							</Link>
						</li>
						{renderMembershipLink()}
						<li>
							<Link to="/business-signup" className="secondary-nav-btn">
								Business Partners
							</Link>
						</li>
						<Link to="/cart">
							<i className="fas fa-shopping-basket"></i>
						</Link>
					</div>

					<div className="side-menu-icon d-lg-none text-end">
						<button onClick={handleShow} className="side-toggle border-0 bg-transparent">
							<i className="fas fa-bars"></i>{' '}
						</button>
					</div>
				</nav>
			</div>

			{/* Services menu */}
			<div className="header__services_menu">
				<nav className="navbar container">
					{navConfig.map((item, index) => {
						if (item.subMenu) {
							return (
								<div
									key={index}
									className="dropdown"
									onMouseEnter={() => setIsDropdownHovered(true)}
									onMouseLeave={() => setIsDropdownHovered(false)}
								>
									<Link to={item.link}>{item.name}</Link>
									<div className="primary-dropdown-content">
										{item.subMenu.map((menu, i) => (
											<div key={i} className="dropdown">
												{menu.category || menu.name}
												<div className="secondary-dropdown-content">
													{renderLinks(menu.links || [])}
												</div>
											</div>
										))}
									</div>
								</div>
							);
						} else {
							return (
								<Link key={index} to={item.link}>
									{item.name}
								</Link>
							);
						}
					})}
				</nav>
			</div>

			{isDropdownHovered ? (
				<div className="backdrop" onClick={() => setIsDropdownHovered(false)}></div>
			) : null}

			<Modal isOpen={isLoginModalOpen} onClose={toggleLoginModal} content={loginModalContent} />

			{/* Mobile menu */}
			<Sidebar show={show} handleClose={handleClose} navConfig={navConfig} />
		</>
	);
};

export default Header;
