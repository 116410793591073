import React, { useEffect, useRef, useState } from 'react';
import styles from './InsuredCustomer.module.scss';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Button } from '@sirkka-health/booking-system-ui';
import { getRequest } from '@/services/api';
import { Header } from '@/components';
import { useCliniciansData } from './postcodeAvailabilityHooks';
import { InsurerTest } from '@/types';

const ALPHABETS_DIRECTORY = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

// A small helper to check lat/long validity
function isValidLocation(lat: number, lng: number) {
	return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
}

/**
 * MinimalMap
 *  - Loads all clinicians from hook
 *  - Automatically fits the map to show all clinician markers
 */
const MinimalMap: React.FC = () => {
	const mapRef = useRef<google.maps.Map | null>(null);

	// 1) Pull data
	const cliniciansData = useCliniciansData();
	const clinicians = Array.isArray(cliniciansData) ? cliniciansData : [];

	// 3) Google Maps API config
	const config = (window as any).envConfig || {};
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: config.REACT_APP_GOOGLE_DISTANCE_MATRIX_API_KEY || '',
	});

	// Once clinicians are loaded, fit the bounds to include all their locations
	useEffect(() => {
		if (!mapRef.current || clinicians.length === 0) return;

		const bounds = new window.google.maps.LatLngBounds();
		clinicians.forEach((clinician: any) => {
			const lat = Number(clinician.latitude);
			const lng = Number(clinician.longitude);
			if (isValidLocation(lat, lng)) {
				bounds.extend(new window.google.maps.LatLng(lat, lng));
			}
		});

		if (!bounds.isEmpty()) {
			mapRef.current.fitBounds(bounds);
		}
	}, [clinicians]);

	if (loadError) return <div>Error loading Google Maps</div>;
	if (!isLoaded) return <div>Loading Map...</div>;

	return (
		<div style={{ width: '100%', height: '600px' }}>
			<GoogleMap
				onLoad={(map) => {
					mapRef.current = map;
				}}
				mapContainerStyle={{ width: '100%', height: '100%' }}
				center={{ lat: 51.5301, lng: -0.1239 }}
				zoom={8}
				options={{
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
				}}
			>
				{clinicians.map((clinician: any) => {
					const lat = Number(clinician.latitude);
					const lng = Number(clinician.longitude);
					if (isValidLocation(lat, lng)) {
						return <Marker key={clinician.user_id} position={{ lat, lng }} />;
					}
					return null;
				})}
			</GoogleMap>
		</div>
	);
};

const InsuredCustomerPage: React.FC = () => {
	const [insurerTests, setInsurerTests] = useState<InsurerTest[]>([]);
	const [selectedLetter, setSelectedLetter] = useState<string | null>(null);

	// Fetch all insurer tests
	useEffect(() => {
		const fetchInsurerTests = async () => {
			try {
				const fetchedTests = await getRequest('/products/insurer?limit=5');
				setInsurerTests(fetchedTests.insurerTests);
			} catch (error) {
				console.error('Error fetching insurer tests:', error);
			}
		};
		fetchInsurerTests();
	}, []);

	const handleAlphabetClick = async (letter: string) => {
		try {
			if (letter === 'All') {
				const fetchedTests = await getRequest('/products/insurer');
				setInsurerTests(fetchedTests.insurerTests);
				setSelectedLetter(null);
			}
			setSelectedLetter(letter);
			const fetchedTests = await getRequest(`/products/insurer?alphabet=${letter}`);
			setInsurerTests(fetchedTests.insurerTests);
		} catch (error) {
			console.error('Error fetching insurer tests by letter:', error);
		}
	};

	return (
		<div>
			<Header title={'Insured Customer'} fullTitle={'Insured Customer'} />
			<div className="hero-bg">
				<div className="container">
					<div className="row py-3 py-lg-5 pb-2 pb-lg-5 flex-column-reverse flex-lg-row">
						{/* <div className="col-lg-5 mx-auto">
							<div>
								<div
									className="px-5 pb-4 pt-4"
									style={{
										backgroundColor: 'rgb(255, 255, 255)',
										padding: 0,
										paddingBottom: '18px',
										borderRadius: '8px',
										border: '1px solid rgba(203, 205, 215, 0.231372549)',
									}}
								>
									<div className="d-flex text-left mb-2">
										<div className="col-12 py-2 px-2" role="button">
											<h6>Search by insurer and tests</h6>
										</div>
									</div>
									<div>
										<div style={{ display: 'flex', gap: '10px' }}>
											<select
												className="mb-3 service-search"
												style={{ flex: 1, padding: '10px' }}
												defaultValue=""
											>
												<option value="" disabled>
													Select insurer
												</option>
												<option value="BUPA">BUPA</option>
											</select>
											<input
												type="text"
												placeholder="Search tests"
												className="mb-3"
												style={{ flex: 1, padding: '10px' }}
											/>
										</div>
									</div>
									<button
										onClick={(e) => e.preventDefault()}
										style={{ textAlign: 'right', display: 'block' }}
										className="mb-2 btn btn-primary"
									>
										Search
									</button>
								</div>
							</div>
						</div> */}
						<div className="col-10 mx-auto align-self-left">
							<div>
								<h3>
									<b>Are you a customer with insurance cover?</b>
								</h3>
								<p className="mt-20">
									<a href="https://sirkkahealth.com/insured/contact-form">
										<b>Book Now</b>
									</a>{' '}
									for a home visit blood tests, or explore the list of tests available.
								</p>
								<p>
									Please ensure that you have your{' '}
									<b>pre-authorisation code, insurance policy number</b>
									and your <b>doctor's test request form/letter/email</b> ready to proceed with the{' '}
									<a href="https://sirkkahealth.com/insured/contact-form">test booking</a>. All
									information must be completed especially your full home address in order for us to
									arrange the blood test at your home or at a clinic closest to you.
								</p>
								<p>
									If you have questions, please email us at{' '}
									<a href="mailto:customers@sirkkaltd.com">customers@sirkkaltd.com</a> and and we
									will respond within 1 business day.
								</p>
							</div>
							<Button
								label="Book Now"
								onClick={() => window.open('/insured/contact-form', '_self')}
							/>
						</div>
					</div>
				</div>
			</div>
			<main className={styles['main-content']}>
				<div className={styles.grid}>
					<div className={styles.card}>
						<div className={styles['card__header']}>
							<h2>Tests {selectedLetter && ` : ${selectedLetter}`}</h2>
						</div>
						<div className={styles['alphabet-nav']}>
							{ALPHABETS_DIRECTORY.map((letter) => (
								<span
									key={letter}
									onClick={() => handleAlphabetClick(letter)}
									className={styles['alphabet-letter']}
								>
									{letter}
								</span>
							))}
							<span
								key="all"
								onClick={() => handleAlphabetClick('All')}
								className={styles['alphabet-letter']}
							>
								Show All
							</span>
						</div>
						<div className={styles['test-list']}>
							{insurerTests && insurerTests.length > 0 ? (
								insurerTests.map((test) => (
									<div key={test.test_id} className={styles['test-item']}>
										<div className={styles['test-item__content']}>
											<div>
												<h3 className={styles['test-item__title']}>{test.test_name}</h3>
												{test.test_description && (
													<p className={styles['test-item__description']}>
														{test.test_description}
													</p>
												)}
											</div>
											{/* <div className={styles['test-item__price']}>
												<Button
													label="Select"
													onClick={() => window.open('/insured/contact-form', '_self')}
													fullWidth
												/>
											</div> */}
										</div>
									</div>
								))
							) : (
								<div className="text-center">No tests available</div>
							)}
						</div>
					</div>

					{/* Right Column - Map Location */}
					<div className={styles.card}>
						<div className={styles['card__header']}>
							<h2>Map Location</h2>
						</div>
						<div className={styles['location__info']} style={{ padding: '1rem' }}>
							<MinimalMap />
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default InsuredCustomerPage;
