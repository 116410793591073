import { useLoadScript } from '@react-google-maps/api';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ReviewArea from './ReviewArea';
import { getRequest } from '../../services/api';
import { useEffect, useState } from 'react';
import config from '../../config';
import LandingPage from './LandingPage/LandingPage';
import { Button } from '@sirkka-health/booking-system-ui';
import { useNavigate } from 'react-router-dom'; // Add this import

const Home = () => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: config.REACT_APP_GOOGLE_MAPS_API_KEY,
	});

	const [locations, setLocations] = useState(['London', 'Edinburgh']);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const navigate = useNavigate();

	const toggleDropdown = (event) => {
		if (event.target.id === 'dropdownMenuLink') {
			event.preventDefault();
		}
		setDropdownOpen(!dropdownOpen);
	};

	useEffect(() => {
		const fetchLocations = async () => {
			const fetchedLocations = await getRequest('/bookings/locationCategory');
			if (Array.isArray(fetchedLocations) && fetchedLocations.length) {
				setLocations(fetchedLocations);
			}
		};
		fetchLocations();
	}, []);

	return (
		<>
			<Header fullTitle="Sirkka Health - Private Healthcare" />
			{/* Temp hero */}
			<div
				className={` px-3 px-sm-0`}
				style={{
					backgroundImage:
						'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(/img/home/hero-bg.png)',
					backgroundPositionY: 'bottom',
					backgroundSize: 'cover',
				}}
			>
				<div className="container">
					<div className="row flex-lg-row flex-column-reverse">
						<div className="pt-0 pb-4 py-lg-5 col-md-12 col-lg-6 d-flex align-items-center">
							<div>
								<div className="row" style={{ padding: '16px 12px', borderRadius: '6px' }}>
									<p className="text-dark pr-sm-100" style={{ fontWeight: 500 }}>
										Sirkka Health is a UK-based integrated medical assistance technology platform
										with user-friendly software and nationwide network of nurses to support
										healthcare and wellbeing service providers.
									</p>
									<p className="text-dark pr-sm-100" style={{ fontWeight: 500 }}>
										We offer an innovative suite of technology tools to enhance healthcare
										providers' visibility, automate their customer management, and provide at home
										or mobile services. Customers can book wellbeing and diagnostic services
										(including home-visit, in-clinic and virtual) online and receive an immediate
										confirmation of date and time.
									</p>
									<div className="col-12 col-sm-auto mb-2 mb-sm-0">
										<div
											className={`dropdown ${dropdownOpen ? 'show' : ''}`}
											onClick={toggleDropdown}
										>
											<a
												className="srk-btn dropdown-toggle"
												style={{
													padding: '10px 20px',
													borderRadius: '8px',
													width: '100%',
													textAlign: 'center',
													fontSize: '14px',
												}}
												href="#"
												role="button"
												id="dropdownMenuLink"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded={dropdownOpen}
											>
												Find out more
											</a>

											<div
												className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}
												style={{
													position: 'absolute',
													transform: 'translate3d(12px, 51px, 0px)',
													top: '0px',
													left: '0px',
													willChange: 'transform',
													padding: '0',
												}}
												aria-labelledby="dropdownMenuLink"
											>
												{' '}
												<div
													style={{
														position: 'absolute',
														top: '-10px',
														left: '10px',
														width: '0',
														height: '0',
														borderTop: 'none',
														borderBottom: '10px solid black',
														borderLeft: '10px solid transparent',
														borderRight: '10px solid transparent',
													}}
												></div>
												<div
													style={{
														position: 'absolute',
														top: '-10px',
														left: '10px',
														width: '0',
														height: '0',
														borderTop: 'none',
														borderBottom: '10px solid #fff',
														borderLeft: '10px solid transparent',
														borderRight: '10px solid transparent',
													}}
												></div>
												<a
													className="dropdown-item"
													href="/wellbeing"
													style={{ padding: '16px 16px 6px' }}
												>
													Wellbeing
												</a>
												<a
													className="dropdown-item"
													href="/international-treatments"
													style={{ padding: '6px 16px' }}
												>
													International Treatments
												</a>
												<a
													className="dropdown-item"
													href="/booking"
													style={{ padding: '6px 16px' }}
												>
													Diagnostic Services
												</a>
												<a
													className="dropdown-item"
													href="/specialists"
													style={{ padding: '6px 16px' }}
												>
													Specialists
												</a>
												<a
													className="dropdown-item"
													href="/gift-cards"
													style={{ padding: '6px 16px' }}
												>
													Gift Cards
												</a>
												<a
													className="dropdown-item"
													href="/membership"
													style={{ padding: '6px 16px 16px' }}
												>
													Membership Options
												</a>
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-auto">
										<Button
											label="Create business account"
											variant="primary"
											onClick={() => {
												navigate('/business-signup');
											}}
											fullWidth
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="pt-3 pb-2 py-lg-5 col-md-12 col-lg-6">
							<div
								className={`pos-rel`}
								style={{
									backgroundImage: 'url(/img/home/clinician-hero.png)',
									backgroundSize: 'cover',
									height: '100%',
									minHeight: '120px',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
								}}
							></div>
						</div>
					</div>
				</div>
			</div>
			{/* End temp hero */}
			{/* <Hero
				listingData={wellbeingData}
				currentPageData={{
					heroTitle: '',
					heroParagraphs: [
						'Many small healthcare and wellbeing service providers struggle with the same problem: their services are difficult to access online. People either do not know about the services they offer, are unable to book appointments, or find it inconvenient to visit in person.',
						'Our digital platform solves all these issues. It offers small healthcare providers an innovative suite of tools to enhance their visibility, automate customer management and administration and provide at home or mobile services. Ultimately the healthcare providers will have more time for their customers and for themselves.',
						'Customers can book wellbeing and diagnostic services (including home-visit, in-clinic and virtual) online and receive an immediate confirmation of date and time.',
					],
					locationPlaceholder: 'Search country',
				}}
			/> */}
			<LandingPage />
			{/* <Hero locations={locations} />
			<ServiceArea /> */}
			{/* <CompanyArea /> */}
			{/* <HomeItWorks /> */}
			<ReviewArea />
			{/* {isLoaded && <LocationArea />} */}
			<Footer />
		</>
	);
};

export default Home;
