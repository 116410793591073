/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './fourRadioSelection.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function FourRadioSelection({
	name,
	label,
	firstOption,
	firstOptionId,
	secondOption,
	secondOptionId,
	thirdOption,
	thirdOptionId,
	fourthOption,
	fourthOptionId,
	onSelectionChange,
	large,
	required,
}) {
	const [selectedValue, setSelectedValue] = useState({
		name,
		value: firstOptionId,
	});

	function handleChange(event) {
		setSelectedValue({
			name,
			value: event.target.id,
		});
		onSelectionChange({
			name,
			value: event.target.id,
		});
	}

	useEffect(() => {
		onSelectionChange(selectedValue);
	}, []); // Fire selection on component mount

	return (
		<div className={`${styles.formGroup} ${styles.fourRadioContainer}`}>
			{label && (
				<label>
					<b>{label}</b>
					{required && <span className={styles.required}>*</span>}
				</label>
			)}
			<div
				className={
					large ? `${styles.fourRadioSelection} ${styles.large}` : styles.fourRadioSelection
				}
			>
				<input
					type="radio"
					name={firstOptionId}
					id={firstOptionId}
					checked={firstOptionId === selectedValue.value}
					value={firstOption}
					onChange={handleChange}
				/>
				<label htmlFor={firstOptionId} className={`${styles.option} ${styles.option1}`}>
					<span>{firstOption}</span>
				</label>

				{large ? <span className={styles.divider}>or</span> : ''}

				<input
					type="radio"
					name={secondOptionId}
					id={secondOptionId}
					checked={secondOptionId === selectedValue.value}
					value={secondOption}
					onChange={handleChange}
				/>
				<label htmlFor={secondOptionId} className={`${styles.option} ${styles.option2}`}>
					<span>{secondOption}</span>
				</label>

				{large ? <span className={styles.divider}>or</span> : ''}

				<input
					type="radio"
					name={thirdOptionId}
					id={thirdOptionId}
					checked={thirdOptionId === selectedValue.value}
					value={thirdOption}
					onChange={handleChange}
				/>
				<label htmlFor={thirdOptionId} className={`${styles.option} ${styles.option3}`}>
					<span>{thirdOption}</span>
				</label>

				{large ? <span className={styles.divider}>or</span> : ''}

				<input
					type="radio"
					name={fourthOptionId}
					id={fourthOptionId}
					checked={fourthOptionId === selectedValue.value}
					value={fourthOption}
					onChange={handleChange}
				/>
				<label htmlFor={fourthOptionId} className={`${styles.option} ${styles.option3}`}>
					<span>{fourthOption}</span>
				</label>
			</div>
		</div>
	);
}

export default FourRadioSelection;

FourRadioSelection.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	large: PropTypes.bool,
	firstOption: PropTypes.string,
	firstOptionId: PropTypes.string,
	secondOption: PropTypes.string,
	secondOptionId: PropTypes.string,
	thirdOption: PropTypes.string, // Third Option
	thirdOptionId: PropTypes.string, // Third Option ID
	fourthOption: PropTypes.string,
	fourthOptionId: PropTypes.string,
	onSelectionChange: PropTypes.func,
	required: PropTypes.bool,
};

FourRadioSelection.defaultProps = {
	name: 'FourRadioSelection',
	label: '',
	large: false,
	firstOption: 'Option 1',
	firstOptionId: 'option-1',
	secondOption: 'Option 2',
	secondOptionId: 'option-2',
	thirdOption: 'Option 3', // Default value for third option
	thirdOptionId: 'option-3', // Default ID for third option
	fourthOption: 'Option 4',
	fourthOptionId: 'option-4',
	onSelectionChange: () => {},
	required: false,
};
