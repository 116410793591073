import { getRequest, postRequest } from '../../services/api';
import { queryCustomer } from '../../services/data';
import getTotal from '../../helpers/getTotal';
import { ReactSession } from 'react-client-session';

const getStripeCustomer = async (customer) => {
	const request = await queryCustomer(customer);
	const customerFound = request?.status === 'success' ? request.customer : null;
	return customerFound;
};

const createStripeCustomer = async (data) => {
	const {
		email,
		address,
		description,
		metadata,
		name,
		payment_method,
		phone,
		shipping,
		coupon,
		id,
		customer_id,
	} = data;

	const dataToBeSubmitted = {
		email,
		address,
		description,
		metadata,
		name,
		payment_method,
		phone,
		shipping,
		coupon,
		id,
		customer_id,
	};

	const customer = await postRequest('/payments/customer', dataToBeSubmitted);

	return customer.customer;
};

const findOrCreateStripeCustomer = async (customer) => {
	const existingStripeCustomer = await getStripeCustomer(customer);
	const stripeCustomer = existingStripeCustomer || (await createStripeCustomer(customer));
	return stripeCustomer;
};

const getStripePrice = async (data) => {
	const request = await getRequest('/payments/prices', data);
	const { price } = request;

	if (price) {
		return price;
	}
};

const createStripePrice = async (priceData) => {
	const prices = await postRequest('/payments/prices', priceData);
	return prices;
};

const findOrCreateStripePrices = async (data = {}) => {
	const existingPrice = await getStripePrice(data);
	const price = existingPrice || (await createStripePrice(data));
	return price;
};

const updateStripeTables = async () => {
	return await postRequest('/payments/update-stripe-tables');
};

const getStripeSubscription = async () => {
	return await postRequest('/payments/get-subscriptions');
};

const createStripeSubscription = async (priceId, customerId, increaseInitialPayment = 0, type) => {
	if (!priceId || !customerId) {
		return {};
	}

	const { subscriptionId, clientSecret } = await postRequest('/payments/subscriptions', {
		priceId,
		customerId,
		increaseInitialPayment,
		type,
	});

	return { subscriptionId, clientSecret };
};

const verifyUserID = async (customerId, user_id) => {
	if (!customerId) {
		return {};
	}

	const { clientSecret, verificationSession } = await postRequest(
		'/verification/create-verification-session',
		{ customerId, user_id }
	);
	return { clientSecret, verificationSession };
};

const subscribeFlow = async (product, customer = null, type = null) => {
	try {
		const stripePricesPromise = Promise.resolve(findOrCreateStripePrices(product));
		const stripeCustomerPromise = Promise.resolve(findOrCreateStripeCustomer(customer));
		const [stripePrices, stripeCustomer] = await Promise.all([
			stripePricesPromise,
			stripeCustomerPromise,
		]);

		const priceId = stripePrices?.price.id;
		const customerId = stripeCustomer?.id;

		if (priceId && customerId) {
			const subscription = await createStripeSubscription(priceId, customerId, 0, type);
			const { clientSecret, subscriptionId } = subscription;
			return { clientSecret, subscriptionId };
		}
	} catch (error) {
		console.error('Error in subscribe flow', error);
		throw error;
	}
};

const verifyUser = async (customerDetails) => {
	const stripeCustomer = await findOrCreateStripeCustomer(customerDetails);
	const customerId = stripeCustomer['id'];

	if (customerId) {
		const { clientSecret, verificationSession } = await verifyUserID(
			customerId,
			customerDetails.user_id
		);
		return { clientSecret, verificationSession };
	}
};

const createStripePaymentIntent = async (data) => {
	return await postRequest('/payments/create-payment-intent', data);
};

const getPaymentIntentDetails = async (paymentIntentID, notes, orderId) => {
	return await getRequest('/payments/get-payment-intent', {
		paymentIntentID,
		notes,
		orderId,
	});
};

const checkoutFlow = async (data, customerDetails, apptDetails) => {
	const stripeCustomer = await findOrCreateStripeCustomer(customerDetails);
	const { id: customer } = stripeCustomer || {};
	const metadata = {
		items: data.items,
		referralId: data.referralId,
		providerName: data.providerName,
		appointment: apptDetails,
	};

	const paymentIntentCreationData = {
		amount: data.amount,
		customer,
		currency: 'gbp',
		receipt_email: customerDetails.email || 'payments@sirkkaltd.com',
		setup_future_usage: 'off_session',
		statement_descriptor: 'Sirkka Health ' + data.name,
		metadata,
	};

	return await createStripePaymentIntent(paymentIntentCreationData);
};

const updatePaymentIntentDetails = async (paymentIntentData) => {
	return await postRequest('/payments/update-payment-intent', paymentIntentData);
};

const makeSubscriptionProductObject = (productData) => {
	if (!productData) return;

	const { name, price, mode } = productData;
	const interval = mode.slice(0, -2);

	return { name, price, interval };
};
const makeBusinessSubscriptionProductObject = (productData) => {
	if (!productData) return;

	const { planType, price, mode } = productData;
	const interval = mode.slice(0, -2);

	return { name: planType, price, interval };
};

const makeCheckoutProductObject = (cartData, discountedTotalPrice) => {
	if (!cartData || !cartData.length) return;

	const amount = discountedTotalPrice ? discountedTotalPrice : getTotal(cartData);

	const name = 'Cart Checkout';
	const items = Array.isArray(cartData)
		? cartData.map((item) => item.service.serviceName).join(', ')
		: '';
	const currency = 'gbp';

	const referralId = ReactSession.get('referralId') || 'organic';

	const provider = Array.isArray(cartData)
		? cartData.map((item) => item.entity.entityName).join(', ')
		: '';

	return { name, amount, currency, items, referralId, provider };
};

const makeCustomerObject = (userDetails) => {
	const email = userDetails.username || userDetails.email || userDetails.email_address;
	const id = userDetails.user_id || null;
	const customer_id = userDetails.customer_id || null;
	const first_name = userDetails.first_name || userDetails.firstName;
	const last_name = userDetails.last_name || userDetails.lastName;
	const name = first_name + ' ' + last_name;
	const phone = userDetails.phone || userDetails.contact_number;

	return { email, id, customer_id, name, phone };
};

export {
	getStripePrice,
	subscribeFlow,
	checkoutFlow,
	makeSubscriptionProductObject,
	makeBusinessSubscriptionProductObject,
	makeCheckoutProductObject,
	makeCustomerObject,
	updatePaymentIntentDetails,
	getPaymentIntentDetails,
	getStripeSubscription,
	updateStripeTables,
	verifyUser,
};
