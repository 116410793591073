import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Subscribe from './Subscribe';
import Checkout from './Checkout';
import {
	checkoutFlow,
	makeBusinessSubscriptionProductObject,
	makeCheckoutProductObject,
	makeCustomerObject,
	makeSubscriptionProductObject,
	subscribeFlow,
} from './helper';
import config from '../../config';

// Call loadStripe outside component to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(config.REACT_APP_STRIPE_PUBLIC_KEY);

const Stripe = ({
	cart,
	price,
	type,
	user,
	submitButton,
	setPaymentIntentId,
	membershipData,
	onPaymentSuccess,
}) => {
	const [clientSecret, setClientSecret] = useState(null);

	const customerUserDetails = makeCustomerObject(user);

	let product;
	let apptDetails;
	if (cart && type === 'subscription') {
		product = makeSubscriptionProductObject(cart[0]);
	}
	if (cart && type === 'business-subscription') {
		product = makeBusinessSubscriptionProductObject(cart[0]);
	}
	if (cart && type === 'checkout') {
		// This should be product name, not customer name
		product = makeCheckoutProductObject(cart, price);
		apptDetails = '';
	}

	const getSecret = async () => {
		let clientSecretRequest;
		if (type === 'subscription' || type === 'business-subscription') {
			clientSecretRequest = await subscribeFlow(product, customerUserDetails, type);
		}
		if (type === 'checkout') {
			clientSecretRequest = await checkoutFlow(product, customerUserDetails, apptDetails);
		}
		if (clientSecretRequest) {
			const { clientSecret, paymentIntentId } = clientSecretRequest;
			setClientSecret(clientSecret);
			setPaymentIntentId(paymentIntentId);
		}
	};

	// <Elements> must be furnished with a correct client secret (i.e. cannot be empty) on its initial render.
	// Get the secret and then stop this going any further without it.
	if (!clientSecret) {
		getSecret();
		return;
	}

	return (
		<Elements
			options={{
				clientSecret,
				appearance: { theme: 'stripe' },
			}}
			stripe={stripePromise}
		>
			{type === 'subscription' ||
				(type === 'business-subscription' && (
					<Subscribe
						type={type}
						submitButton={submitButton}
						customerUserDetails={customerUserDetails}
						onPaymentSuccess={onPaymentSuccess}
					/>
				))}
			{type === 'checkout' && (
				<Checkout price={price} submitButton={submitButton} membershipData={membershipData} />
			)}
		</Elements>
	);
};

export default Stripe;
