import React, { useState } from 'react';
import styles from './ContactForm.module.scss';
import showAlert from '@/helpers/alerts';
import axios from 'axios';
import config from '@/config';
import { useNavigate } from 'react-router-dom';

const InsuredCustomerContactForm = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		insurer: '',
		preAuthCode: '',
		insurancePolicyNumber: '',
		firstName: '',
		lastName: '',
		dateOfBirth: '',
		email: '',
		phoneNumber: '',
		homeAddress: '',
		postcode: '',
		referringClinician: '',
		referringClinicianContact: '',
		referringClinicianEmail: '',
		preferredDate: '',
		preferredTime: '',
		testRequest: '',
		bloodTestLocation: '',
		comments: '',
		forWhom: '',
		relationship: 'n/a',
	});
	const [attachment, setAttachment] = useState<File | null>(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleForWhom = (value: string) => {
		setFormData({ ...formData, forWhom: value });
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.target;

		setFormData((prev) => {
			let currentSelections = prev.bloodTestLocation
				? prev.bloodTestLocation.split(',').map((item) => item.trim())
				: [];

			if (checked) {
				if (!currentSelections.includes(value)) {
					currentSelections.push(value);
				}
			} else {
				currentSelections = currentSelections.filter((item) => item !== value);
			}

			const updatedString = currentSelections.join(', ');

			return {
				...prev,
				bloodTestLocation: updatedString,
			};
		});
	};
	function camelCaseToLabel(camelCase: string) {
		let label = camelCase.replace(/([A-Z])/g, ' $1');
		label = label.charAt(0).toUpperCase() + label.slice(1);
		return label;
	}

	const validateContactForm = () => {
		const optionalFields = ['testRequest', 'comments'];
		for (const [key, value] of Object.entries(formData)) {
			if (optionalFields.includes(key)) {
				continue;
			}
			if (!value && key !== 'relationship' && formData.forWhom !== 'Others') {
				showAlert(`Please fill in the ${camelCaseToLabel(key)} field.`);
				return false;
			}
			if (formData.forWhom === 'Others' && key === 'relationship' && !value) {
				showAlert('Please fill in the Relationship field.');
				return false;
			}
			const today = new Date().toISOString().split('T')[0];
			if (formData.preferredDate < today) {
				showAlert('Preferred date cannot be in the past. Please select a valid date.');
				return false;
			}
		}
		if (!formData.bloodTestLocation) {
			showAlert('Please select at least one location for your blood tests.');
			return false;
		}
		return true;
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validateContactForm()) {
			return;
		}

		const combinedHomeAddress = `${formData.homeAddress}, ${formData.postcode}`;
		const updatedFormData = {
			...formData,
			homeAddress: combinedHomeAddress,
		};

		const data = new FormData();
		Object.entries(updatedFormData).forEach(([key, value]) => {
			data.append(key, value);
		});

		if (attachment) {
			data.append('attachment', attachment);
		}
		const response = await axios.post(
			`${config.REACT_APP_API_URL}/contact/insured-test-request`,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		if (response && response.data.status === 'success') {
			showAlert(
				'Your form has been sent to us. Our team will reach out to you shortly.',
				null,
				'success',
				true,
				true,
				false,
				'/',
				navigate
			);
			setFormData({
				insurer: '',
				preAuthCode: '',
				insurancePolicyNumber: '',
				firstName: '',
				lastName: '',
				dateOfBirth: '',
				email: '',
				phoneNumber: '',
				homeAddress: '',
				postcode: '',
				referringClinician: '',
				referringClinicianContact: '',
				referringClinicianEmail: '',
				preferredDate: '',
				preferredTime: '',
				testRequest: '',
				bloodTestLocation: '',
				comments: '',
				forWhom: '',
				relationship: '',
			});
		} else {
			showAlert('Failed to send message. Please check your details and try again.');
		}
	};

	return (
		<>
			<div className={styles['contact-form-container']}>
				<h1 className={styles['form-title']}>Pre-Authorisation Contact Form</h1>
				<p className="text-center">
					Please fill out all the fields below before submitting. Our staff will reach out to you
					shortly.
				</p>
				<form onSubmit={handleSubmit} className={styles['contact-form']}>
					<div className={styles['form-group']}>
						<label htmlFor="insurer">Insurer</label>
						<select
							id="insurer"
							name="insurer"
							value={formData.insurer}
							onChange={handleChange}
							required
							className={styles['form-select']}
						>
							<option value="" disabled>
								Select insurer
							</option>
							<option value="Bupa">Bupa</option>
						</select>
					</div>

					<div className={styles['twoFieldResponsive']}>
						<div className={styles['form-group']}>
							<label htmlFor="preAuthCode">Pre-Authorisation Code</label>
							<input
								type="text"
								id="preAuthCode"
								name="preAuthCode"
								value={formData.preAuthCode}
								onChange={handleChange}
								placeholder="Enter pre-authorisation code"
								required
							/>
						</div>
						<div className={styles['form-group']}>
							<label htmlFor="insurancePolicyNumber">Insurance Policy Number</label>
							<input
								type="number"
								id="insurancePolicyNumber"
								name="insurancePolicyNumber"
								value={formData.insurancePolicyNumber}
								onChange={handleChange}
								placeholder="Enter insurance policy number"
								required
							/>
						</div>
					</div>
					<h2 className={styles['section-title']}>Referrer Details</h2>
					<div className={styles['form-group']}>
						<label htmlFor="referringClinician">Referring Clinician</label>
						<input
							type="text"
							id="referringClinician"
							name="referringClinician"
							value={formData.referringClinician}
							onChange={handleChange}
							placeholder="Enter referring clinician name"
							required
						/>
					</div>
					<div className={styles['form-group']}>
						<label htmlFor="referringClinicianContact">
							Contact phone number for referring clinician
						</label>
						<input
							type="text"
							id="referringClinicianContact"
							name="referringClinicianContact"
							value={formData.referringClinicianContact}
							onChange={handleChange}
							placeholder="Enter referring clinician's phone number"
							required
						/>
					</div>
					<div className={styles['form-group']}>
						<label htmlFor="referringClinicianEmail">
							Email for referring clinician{' '}
							<i>(Note: Your test results will be sent to your referring clinician.)</i>
						</label>
						<input
							type="text"
							id="referringClinicianEmail"
							name="referringClinicianEmail"
							value={formData.referringClinicianEmail}
							onChange={handleChange}
							placeholder="Enter referring clinician's email address"
							required
						/>
					</div>
					<div className={styles['form-group']}>
						<label htmlFor="attachment">
							Clinician referral letter{' '}
							<i>(Note: This is required to determine the tests you will need)</i>
						</label>
						<input
							type="file"
							id="attachment"
							accept="application/pdf,image/*"
							onChange={(e) => {
								if (e.target.files && e.target.files.length > 0) {
									const file = e.target.files[0];
									const sizeLimit = 5 * 1024 * 1024;
									if (file.size > sizeLimit) {
										showAlert('The file size exceeds the 5MB limit. Please upload a smaller file.');
										e.target.value = '';
										return;
									}
									setAttachment(file);
								}
							}}
						/>
					</div>

					<div className={styles['form-group']}>
						<label>Is this test request for:</label>
						<div className={styles['toggle-buttons']}>
							<button
								type="button"
								className={`${styles['toggle-button']} ${
									formData.forWhom === 'Myself' ? styles['active'] : ''
								}`}
								onClick={() => handleForWhom('Myself')}
							>
								Myself
							</button>
							<button
								type="button"
								className={`${styles['toggle-button']} ${
									formData.forWhom === 'Others' ? styles['active'] : ''
								}`}
								onClick={() => handleForWhom('Others')}
							>
								Others
							</button>
						</div>
					</div>

					{formData.forWhom && (
						<>
							<h2 className={styles['section-title']}>Personal Details</h2>
							{formData.forWhom === 'Others' && (
								<p>
									You have selected <strong>For others</strong>. Please fill in the personal details
									of that individual here:
								</p>
							)}
							{formData.forWhom === 'Others' && (
								<div className={styles['form-group']}>
									<label htmlFor="relationship">
										What is your relationship to the person that you are requesting blood tests for?
										<br />
										<i>
											(Note: By providing further information below, you hereby confirm that you
											have obtained the consent from the individual to request the blood tests on
											their behalf.)
										</i>
									</label>
									<input
										type="text"
										id="relationship"
										name="relationship"
										value={formData.relationship}
										onChange={handleChange}
										placeholder="Enter relationship to person"
										required
									/>
								</div>
							)}
							<div className={styles['twoFieldResponsive']}>
								<div className={styles['form-group']}>
									<label htmlFor="firstName">First Name</label>
									<input
										type="text"
										id="firstName"
										name="firstName"
										value={formData.firstName}
										onChange={handleChange}
										placeholder="Enter first name"
										required
									/>
								</div>
								<div className={styles['form-group']}>
									<label htmlFor="lastName">Last Name</label>
									<input
										type="text"
										id="lastName"
										name="lastName"
										value={formData.lastName}
										onChange={handleChange}
										placeholder="Enter last name"
										required
									/>
								</div>
							</div>
							<div className={styles['form-group']}>
								<label htmlFor="dateOfBirth">Date of Birth</label>
								<input
									type="date"
									id="dateOfBirth"
									name="dateOfBirth"
									value={formData.dateOfBirth}
									onChange={handleChange}
									required
								/>
							</div>
							<div className={styles['form-group']}>
								<label htmlFor="email">Email Address</label>
								<input
									type="email"
									id="email"
									name="email"
									value={formData.email}
									onChange={handleChange}
									placeholder="Enter email address"
									required
								/>
							</div>
							<div className={styles['form-group']}>
								<label htmlFor="phoneNumber">Phone Number</label>
								<input
									type="tel"
									id="phoneNumber"
									name="phoneNumber"
									value={formData.phoneNumber}
									onChange={handleChange}
									placeholder="Enter phone number"
									required
								/>
							</div>
							<div className={styles['form-group']}>
								<label htmlFor="homeAddress">
									Home Address
									<i>(Note: Please fill in your full home address.)</i>
								</label>
								<input
									type="text"
									id="homeAddress"
									name="homeAddress"
									value={formData.homeAddress}
									onChange={handleChange}
									placeholder="Enter your street name, house number, etc."
									required
								/>
							</div>
							<div className={styles['form-group']}>
								<label htmlFor="postcode">Postcode</label>
								<input
									type="text"
									id="postcode"
									name="postcode"
									value={formData.postcode}
									onChange={handleChange}
									placeholder="Enter your postcode"
									required
								/>
							</div>
						</>
					)}

					<h2 className={styles['section-title']}>Appointment Details</h2>
					<div className={styles['form-group']}>
						<label htmlFor="preferredDate">Preferred Date</label>
						<input
							type="date"
							id="preferredDate"
							name="preferredDate"
							value={formData.preferredDate}
							onChange={handleChange}
							min={new Date().toISOString().split('T')[0]}
							required
						/>
					</div>
					<div className={styles['form-group']}>
						<label htmlFor="preferredTime">Preferred Time</label>
						<input
							type="time"
							id="preferredTime"
							name="preferredTime"
							value={formData.preferredTime}
							onChange={handleChange}
							required
						/>
					</div>
					<div className={styles['form-group']}>
						<label>
							Which of the following options would you like for your blood tests to be done?{' '}
						</label>
						<div className={styles['checkbox-group']}>
							<label>
								<input
									type="checkbox"
									name="bloodTestLocation"
									value="Home visit"
									checked={formData.bloodTestLocation.includes('Home visit')}
									onChange={handleCheckboxChange}
								/>
								Home visit
							</label>
							<label>
								<input
									type="checkbox"
									name="bloodTestLocation"
									value="Clinic"
									checked={formData.bloodTestLocation.includes('Clinic')}
									onChange={handleCheckboxChange}
								/>
								In clinic
							</label>
						</div>
					</div>
					<div className={styles['form-group']}>
						<label htmlFor="testRequest">Test Request</label>
						<input
							type="text"
							id="testRequest"
							name="testRequest"
							value={formData.testRequest}
							onChange={handleChange}
							placeholder="Enter details on the test required"
						/>
					</div>
					<div className={styles['form-group']}>
						<label htmlFor="comments">Any other comments:</label>
						<input
							type="text"
							id="comments"
							name="comments"
							value={formData.comments}
							onChange={handleChange}
							placeholder="Enter notes or comments"
						/>
					</div>
					<div className="contact-btn text-center">
						<button className="primary_btn ml-0" type="submit">
							Submit request
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default InsuredCustomerContactForm;
